import Moment from 'moment';
import ReactJson from 'react-json-view';
import T from 'i18n-react';

import { useEffect, useMemo, useState } from 'react';

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    IconButton,
    Paper,
    Typography,
    withStyles
} from '@material-ui/core';

import {
    Code,
    Refresh
} from '@material-ui/icons';
import ExportIcon from '@material-ui/icons/CloudDownload';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import useCsvExport from '@apricityhealth/web-common-lib/hooks/useCsvExport';
import useReportJob from '@apricityhealth/web-common-lib/hooks/useReportJob';

import styles from './RecordsImportPage.styles';


const NIGHTLY_IMPORT_REPORT_NAME = 'NightlyRecordImport';
const RESOURCE_TYPES = [
    "Patient",
    "AllergyIntolerance",
    "CarePlan", "Claim",
    "DiagnosticReport", "DocumentReference",
    "Encounter",
    "Goal",
    "Immunization",
    "Observation",
    "Procedure",
    "MedicationOrder", "MedicationRequest", "MedicationStatement",
];

let reloadTimer = null;


/**
 * Page that displays a summary of records imported from Patient360® for all patients on a given night
 */
function RecordsImportPage({ classes }) {
    const [importDate, setImportDate] = useState(Moment()),
        [processingJob, setProcessingJob] = useState(false),
        [showCode, setShowCode] = useState(false);

    let [{
        data: reportList,
        result: report,
        error, loading,
        fetchJobs, fetchJobResult, postJob
    }] = useReportJob();

    let [{
        appendTable,
        clearCsvWorkbook,
        exportCsv
    }] = useCsvExport()


    useEffect(() => {
        internalFetchReports();
        return () => {
            clearInterval(reloadTimer);
            reloadTimer = null;
        }
    }, []);

    useEffect(() => {
        let currentJob = null, completedJob = null;
        if (reportList && reportList.length > 0) {
            currentJob = reportList[0];
            completedJob = reportList.find(j => j.status === 'done');
        }

        if (completedJob) try {
            setImportDate(completedJob.args.date);
            fetchJobResult(completedJob);
        } catch (e) {
            console.error(e);
        }

        if (currentJob) {
            if (currentJob?.status === 'active')
                setProcessingJob(true);
            if (currentJob?.status === 'active' && !reloadTimer)
                reloadTimer = setInterval(internalFetchReports, 10000);// 10s
            if (currentJob?.status !== 'active' && reloadTimer) {
                setProcessingJob(false);
                clearInterval(reloadTimer);
                reloadTimer = null;
            }
        }
    }, [reportList]);


    const tableData = useMemo(() => {
        const orgs = report?.orgs;
        const tables = [];

        if (!orgs) return;

        for (const orgId in orgs) {
            const orgMetrics = orgs[orgId];
            const rows = [];

            if (orgMetrics.inactive === undefined)
                orgMetrics.inactive = false;

            rows.push(<tr>
                <th>EmrId</th>
                {RESOURCE_TYPES.map((resourceType) => <th>{resourceType}</th>)}
            </tr>);
            for (const emrId in orgMetrics.metrics.imported) {
                const importMetrics = orgMetrics.metrics.imported[emrId].resourceTypes;
                rows.push(<tr key={emrId}>
                    <th>{emrId}</th>
                    {RESOURCE_TYPES.map((resourceType) => <td>{importMetrics[resourceType] || 0}</td>)}
                </tr>)
            }
            tables.push(<table key={orgMetrics.name} className={classes.table}>
                <thead>
                    <tr>
                        <th colSpan={RESOURCE_TYPES.length + 1}>{orgMetrics.name}</th>
                    </tr>
                    <tr>
                        <td>Patients Imported: {orgMetrics.metrics.patients}</td>
                        <td colSpan={2}> Org Active: {(orgMetrics.inactive === false) ? "true" : "false"}</td>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>,
            <br />);
        }

        return tables;
    }, [report]);


    function downloadCsv() {
        const FORMAT = "YYYY-MM-DD",
            DATE = Moment(importDate).format(FORMAT);

        clearCsvWorkbook();
        tableData.forEach(elem => {
            if (elem.type === 'table')
                appendTable({ title: elem.key, htmlTable: elem });
        });
        exportCsv({ fileName: `${NIGHTLY_IMPORT_REPORT_NAME}_${DATE}` });
    };

    function internalFetchReports() {
        try {
            const args = [`reportName=${NIGHTLY_IMPORT_REPORT_NAME}`],
                params = ['*'];
            fetchJobs(params, args);
        } catch (error) {
            console.error('error fetching job:', error);
        }
    };

    function createJob() {
        const job = {
            reportName: NIGHTLY_IMPORT_REPORT_NAME,
            isSeries: true,
            args: { date: importDate }
        };
        postJob(job);
        setProcessingJob(true);
        reloadTimer = setInterval(internalFetchReports, 10000);// 10s
    };


    return <div className={classes.root}>
        <div className={classes.box}>
            <Typography variant='h4'>Nightly Records Import (Patient360<sup>®</sup>)</Typography>
            <div className={classes.row}>
                <div className={classes.left}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker label='Import Date' value={importDate} onChange={setImportDate} />
                    </MuiPickersUtilsProvider>
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={(loading || processingJob)}
                        onClick={createJob}
                    >
                        {T.translate("regenerate")}
                    </Button>
                    <IconButton
                        color='primary'
                        disabled={(loading || processingJob)}
                        onClick={downloadCsv}
                    >
                        <ExportIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        disabled={loading}
                        onClick={() => setShowCode(true)}
                    >
                        <Code />
                    </IconButton>
                    <IconButton color='primary' onClick={internalFetchReports}>
                        {loading || processingJob ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            {error && <Typography variant='error'>{error}</Typography>}
            <br />
            {((loading || processingJob) && <div className={classes.left}>
                <Typography>Loading Metrics...</Typography>
            </div>) || ((!processingJob && !report) && <div className={classes.left}>
                <Typography>Generate a new report</Typography>
            </div>)}
            {tableData}
        </div>
        <Dialog fullWidth maxWidth='md' open={showCode}>
            <DialogTitle>Nightly Records Import JSON</DialogTitle>
            <DialogContent>
                <Paper key='JSON-view' className={classes.jsonPaper}>
                    <ReactJson name='Nightly Records Import' src={{ job: reportList, report }} collapsed={3}
                        collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'
                    onClick={() => setShowCode(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>;
};


export default withStyles(styles)(RecordsImportPage);