
const drawerWidth = 300;

const styles = ({ spacing, mixins, transitions, palette }) => ({
    root: {
        display: "flex"
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    appBar: {
        backgroundColor: palette?.background?.default,
        height: '6rem',
        ...mixins.toolbar,
    },
    // necessary for content to be below app bar
    toolbar: {
        height: '6rem',
        ...mixins.toolbar
    },
    drawerPaper: {
        marginTop: "95px",
        width: drawerWidth
    },
    // content: {
    //     flexGrow: 1,
    //     padding: spacing(3)
    // },
    content: {
        flexGrow: 1,
        padding: spacing(3),
        transition: transitions.create('margin', {
            easing: transitions.easing.sharp,
            duration: transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: transitions.create('margin', {
            easing: transitions.easing.easeOut,
            duration: transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },

})
export default styles