import EmployerPage from './pages/employer';
import PatientOnboardingMetricsView from './pages/onboarding';
import ProviderUtilizationPage from './pages/provider';
import RecordsImportPage from './pages/records-import';
import VCCProviderMetricsPage from './pages/vcc';

import {
    BarChartOutlined,
    DashboardOutlined,
    SupervisorAccount,
    TransferWithinAStationOutlined,
    WbIncandescentOutlined,
    WorkOutlineOutlined
} from '@material-ui/icons';


const routes = [
    DashboardOutlined,
    {
        mainNavName: 'Platform Insight',
        path: '/insights',
        environments: ['develop', 'test', 'demo', 'vcc', 'production'],
        icon: WbIncandescentOutlined,
        children: [
            {
                mainNavName: 'Onboarding Metrics',
                path: '/insights/onboarding-metrics',
                exact: true,
                environments: ['develop', 'test', 'demo', 'vcc', 'production'],
                element: PatientOnboardingMetricsView,
                icon: TransferWithinAStationOutlined
            },
            {
                mainNavName: 'Nightly P360 import',
                path: '/insights/records-import',
                exact: true,
                environments: ['develop', 'test', 'demo', 'vcc', 'production'],
                element: RecordsImportPage,
                icon: BarChartOutlined
            },
            {
                mainNavName: 'Provider Utilization',
                path: '/insights/provider-utilization',
                exact: true,
                environments: ['develop', 'test', 'demo', 'vcc', 'production'],
                element: ProviderUtilizationPage,
                icon: WorkOutlineOutlined
            },
            {
                mainNavName: 'Employer Metrics',
                path: '/insights/employer-metrics',
                exact: true,
                environments: ['develop', 'test', 'demo', 'production'],
                element: EmployerPage,
                icon: SupervisorAccount
            },
            {
                mainNavName: 'Oncologist Metrics',
                path: '/insights/vcc-provider-metrics',
                exact: true,
                environments: ['develop', 'test', 'demo', 'vcc'],
                element: VCCProviderMetricsPage,
                icon: SupervisorAccount
            }
        ]
    }
];


export default routes;