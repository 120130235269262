const styles = ({ spacing, shadows, palette, topography, mixins }) => ({
    root: {
        backgroundColor: palette?.background?.page,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: mixins?.borderRadius,
        marginRight: '20px',
        minHeight: 'calc(90vh - 88px)',
        width: '100%',
        padding: '20px',
        "&.MuiDataGrid-root": {
            border: '0px solid',
            color: palette.text.primary
        }
    },
    box: {
        backgroundColor: palette?.background?.paper,
        border: '1px solid',
        borderColor: palette.divider,
        borderRadius: mixins?.borderRadius,
        boxShadow: shadows[2],
        display: 'inline-flex',
        flexDirection: 'column',
        gap: '1rem',
        minHeight: '80vh',
        overflowX: 'hidden',
        padding: '1rem',
        width: '100%'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    left: {
        marginLeft: '1rem'
    },
    right: {
        marginRight: '1rem'
    },

    card: {
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center'
    },
    cardCollapse: {
        transform: 'rotate(0deg)'
    },
    cardExpand: {
        transform: 'rotate(180deg)'
    },
    cardContent: {
        height: '40vh',
        width: '66vw'
    },
    chip: {
        border: '1px solid',
        color: palette?.primary?.main,
        textAlign: 'center'
    },
    collapse: {
        alignSelf: 'center',
        maxWidth: '38vw',
        overflow: 'scroll'
    },
    grid: {
        backgroundColor: palette?.background?.page,
        borderRadius: mixins?.borderRadius,
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'space-evenly',
        padding: '1rem'
    },
    table: {
        borderCollapse: 'collapse',
        fontSize: '1rem',
        width: '100%'
    },
    tableCell: {
        borderBottom: '0.1rem solid #A9A9A9',
        height: '1.25rem',
        padding: '0.25rem',
        textAlign: 'left'
    }
});


export default styles;