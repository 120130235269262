import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import Store from '@apricityhealth/web-common-lib/reduxStore';
import AppProvider from '@apricityhealth/web-common-lib/AppProvider';

import App from './App'
import './index.css';

const store = Store();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <AppProvider>
                <App />
            </AppProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
)