import Moment from 'moment';
import ReactJson from 'react-json-view';
import T from 'i18n-react';

import { useEffect, useMemo, useState } from 'react';

import {
    Button,
    Dialog, DialogActions, DialogContent, DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Paper,
    Select,
    Typography,
    withStyles
} from '@material-ui/core';
import {
    Code,
    Refresh
} from '@material-ui/icons';
import ExportIcon from '@material-ui/icons/CloudDownload';

import { Loading } from '@apricityhealth/web-common-lib/components/Loading';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import MultiSelectOrg from '@apricityhealth/web-common-lib/components/MultiSelectOrg';
import useCsvExport from '@apricityhealth/web-common-lib/hooks/useCsvExport';
import useReportJob from '@apricityhealth/web-common-lib/hooks/useReportJob';

import styles from './ProviderUtilizationPage styles';


const PROVIDER_UTILIZATION_REPORT_NAME = 'ProviderUtilization'

let reloadTimer = null;


/**
 * Convert a given time `interval` (in seconds) to the specified unit of time
 * @param {Number} interval Time in Seconds
 * @param {'seconds'|'minutes'|'hours'} unit Time unit to convert to
 * @returns {Number}
 */
function convertFromSeconds(interval, unit) {
    switch (unit) {
        case 'seconds':
            return interval.toFixed(2);
        case 'minutes':
            return (interval / 60).toFixed(2);
        case 'hours':
            return (interval / 3600).toFixed(2);
        default:
            console.warn('invalid unit (must be seconds, minutes, or hours');
            return interval;
    };
};


/**
 * Page that displays a summary of each Apricity Organization's Providers in-app utilization.
 * `[Patient-Triage, Tele-Medicine, ...]`
 */
function ProviderUtilizationPage({ classes }) {
    const [endTime, setEndTime] = useState(Moment()),
        [startTime, setStartTime] = useState(Moment().subtract(1, 'month')),
        [showCode, setShowCode] = useState(false),
        [timeInterval, setTimeInterval] = useState('minutes'),
        [processingReport, setProcessingReport] = useState(false),
        [orgIds, setOrgIds] = useState('*');

    let [{
        data: jobList,
        result: jobResult,
        error, loading,
        fetchJobs, fetchJobResult, postJob
    }] = useReportJob();

    let [{
        appendTable,
        clearCsvWorkbook,
        exportCsv
    }] = useCsvExport();


    useEffect(() => {
        let currentJob = null, completedJob = null
        if (jobList && jobList.length > 0) {
            currentJob = jobList[0];
            completedJob = jobList.find(j => j.status === 'done');
        }

        if (completedJob) try {
            setStartTime(completedJob.args.startTime || completedJob.args.startDate);
            setEndTime(completedJob.args.endTime || completedJob.args.endDate);
            setOrgIds(completedJob.args.orgIds || completedJob.args.orgId);
            fetchJobResult(completedJob);
        } catch (e) {
            console.error(e);
        }

        if (currentJob) {
            if (currentJob?.status === 'active')
                setProcessingReport(true);
            if (currentJob?.status === 'active' && !reloadTimer)
                reloadTimer = setInterval(internalFetchReport, 10000);// 10s
            if (currentJob?.status !== 'active' && reloadTimer) {
                setProcessingReport(false);
                clearInterval(reloadTimer);
                reloadTimer = null;
            }
        }
    }, [jobList]);

    useEffect(() => {
        internalFetchReport();
        return () => {
            clearInterval(reloadTimer);
            reloadTimer = null;
        };
    }, []);

    const tableData = useMemo(() => {
        const metrics = jobResult?.orgs;
        const tables = [];

        if (!metrics)
            return;

        for (const orgId in metrics) {
            const orgMetrics = metrics[orgId];
            const rows = [];

            for (const providerId in orgMetrics.providers) {
                const providerMetrics = orgMetrics.providers[providerId];
                rows.push(<tr key={providerId}>
                    <td>{providerMetrics.name}</td>
                    <td>{providerMetrics.statistics.triageResponseTime.red.count}</td>
                    <td>{providerMetrics.statistics.triageResponseTime.orange.count}</td>
                    <td>{providerMetrics.statistics.triageResponseTime.yellow.count}</td>
                    <td>{providerMetrics.statistics.triageResponseTime.green.count}</td>
                    <td>{providerMetrics.triage.viewed}</td>
                    <td>{providerMetrics.triage.completed}</td>
                    <td>{providerMetrics.triage.pending}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.red.average, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.red.shortest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.red.longest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.orange.average, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.orange.shortest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.orange.longest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.yellow.average, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.yellow.shortest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.yellow.longest, timeInterval)}</td>
                    {/* <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.green.average, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.green.shortest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.triageResponseTime.green.longest, timeInterval)}</td> */}
                    <td title='Total # of appointments'>{providerMetrics.statistics.telehealth.count}</td>
                    <td title='# of appointments currently pending'>{providerMetrics.statistics.telehealth.requested}</td>
                    <td>{providerMetrics.statistics.telehealth.rejected}</td>
                    <td>{providerMetrics.statistics.telehealth.confirmed}</td>
                    <td title='# of appointments where both patient & provider connected'>{providerMetrics.statistics.telehealth.completed}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.telehealth.average, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.telehealth.longest, timeInterval)}</td>
                    <td>{convertFromSeconds(providerMetrics.statistics.telehealth.shortest, timeInterval)}</td>
                </tr>);
            }

            tables.push(<table key={orgMetrics.name} className={classes.table}>
                <thead>
                    <tr>
                        <Typography align='center' variant='h5'>{orgMetrics.name}</Typography>
                    </tr>
                    <tr>
                        <td>Appointments Enabled: {orgMetrics.enableAppointments ? "Yes" : "No"}</td>
                        <td>Alert Filters: {isArrayValid(orgMetrics.alertFilters) ? orgMetrics.alertFilters.map(f => f.category).join(', ') : "None"} </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Provider</td>
                        <td>Red Alerts</td>
                        <td>Orange Alerts</td>
                        <td>Yellow Alerts</td>
                        <td>Green Alerts</td>
                        <td>Triage's Viewed</td>
                        <td>Triage's Completed</td>
                        <td>Triage's Pending</td>
                        <td>Red Alert Avg. RT.</td>
                        <td>Red Alert Shortest RT.</td>
                        <td>Red Alert Longest. RT.</td>
                        <td>Orange Alert Avg. RT.</td>
                        <td>Orange Alert Shortest RT.</td>
                        <td>Orange Alert Longest RT.</td>
                        <td>Yellow Alert Avg. RT.</td>
                        <td>Yellow Alert Shortest RT.</td>
                        <td>Yellow Alert Longest RT.</td>
                        {/* <td>Green Alert Avg. RT.</td>
                        <td>Green Alert Shortest RT.</td>
                        <td>Green Alert Longest RT.</td> */}
                        <td>Total # of Appointments</td>
                        <td>Appointments Requested</td>
                        <td>Appointments Rejected</td>
                        <td>Appointments Confirmed</td>
                        <td>Appointments Completed</td>
                        <td>TeleHealth Avg. Call</td>
                        <td>TeleHealth Longest Call</td>
                        <td>TeleHealth Shortest Call</td>
                    </tr>
                    {rows}
                </tbody>
            </table>,
            <br />);
        }

        return tables;
    }, [jobResult, timeInterval]);


    function downloadCsv() {
        const FORMAT = "YYYY-MM-DD",
            START = Moment(startTime).format(FORMAT),
            END = Moment(endTime).format(FORMAT);

        clearCsvWorkbook();
        tableData.forEach(elem => {
            if (elem.type === 'table')
                appendTable({ title: elem.key, htmlTable: elem });
        });
        exportCsv({ fileName: `${PROVIDER_UTILIZATION_REPORT_NAME}_${START}_Thru_${END}` });
    };

    function internalFetchReport() {
        try {
            const args = [`reportName=${PROVIDER_UTILIZATION_REPORT_NAME}`],
                params = ['*'];
            fetchJobs(params, args);
        } catch (error) {
            console.error('error fetching job', error);
        }
    };

    function createJob() {
        const job = {
            reportName: PROVIDER_UTILIZATION_REPORT_NAME,
            isSeries: true,
            args: { startTime, endTime, orgIds }
        };
        postJob(job);
        setProcessingReport(true);
        reloadTimer = setInterval(internalFetchReport, 10000);// 10s
    };


    return <div className={classes.root}>
        <div className={classes.box}>
            <Typography variant='h4'>Provider Utilization</Typography>
            <div className={classes.row}>
                <div className={classes.left}>
                    <DateRange key='date'
                        format={'MM/dd/yyyy'}
                        startTime={startTime}
                        endTime={endTime}
                        endTimeChange={(time) => { setEndTime(Moment(time).endOf('day').toDate()) }}
                        startTimeChange={(time) => { setStartTime(Moment(time).startOf('day').toDate()) }}
                    />
                    <MultiSelectOrg enableRosterCheck selectedValue={orgIds}
                        onChange={({ orgId }) => setOrgIds(orgId)}
                    />
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={(loading || processingReport)}
                        onClick={createJob}
                    >
                        {T.translate("regenerate")}
                    </Button>
                    <IconButton
                        color='primary'
                        disabled={(loading || processingReport)}
                        onClick={downloadCsv}
                    >
                        <ExportIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        disabled={loading}
                        onClick={() => setShowCode(true)}
                    >
                        <Code />
                    </IconButton>
                    <IconButton color='primary' onClick={internalFetchReport}>
                        {(loading || processingReport) ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.left}>

                </div>
                <div className={classes.right}>
                    <FormControl>
                        <Select value={timeInterval} onChange={(e) => setTimeInterval(e.target.value)}>
                            <MenuItem value='seconds'>Seconds</MenuItem>
                            <MenuItem value='minutes'>Minutes</MenuItem>
                            <MenuItem value='hours'>Hours</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
            {error && <Typography variant='error'>{error}</Typography>}
            <br />
            {((loading || processingReport) && <div className={classes.left}>
                <Typography>Loading Metrics...</Typography>
            </div>) || ((!processingReport && !jobResult) && <div className={classes.left}>
                <Typography>Generate a new report</Typography>
            </div>)}
            {tableData}
        </div>
        <Dialog fullWidth maxWidth='md' open={showCode}>
            <DialogTitle>Provider Utilization Metrics JSON</DialogTitle>
            <DialogContent>
                <Paper key='JSON-view' className={classes.jsonPaper}>
                    <ReactJson name='Provider Utilization' src={{job: jobList, jobResult}} collapsed={3}
                        collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'
                    onClick={() => setShowCode(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>;
};


export default withStyles(styles)(ProviderUtilizationPage);