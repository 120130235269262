import T from 'i18n-react';
import Moment from 'moment-timezone';
import ReactJson from 'react-json-view';

import { useState, useEffect, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';

import {
    withStyles,
    Button,
    Card, CardHeader, CardContent,
    Chip,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    List, ListItem, ListItemIcon, ListItemText,
    MenuItem,
    Paper,
    Select,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    Code,
    Help,
    LooksOneOutlined,
    LooksTwoOutlined,
    Refresh
} from '@material-ui/icons';

import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import MultiSelectOrg from '@apricityhealth/web-common-lib/components/MultiSelectOrg';
import ProgressText from '@apricityhealth/web-common-lib/components/ProgressText';

import useReportJob from '@apricityhealth/web-common-lib/hooks/useReportJob';

import styles from './VCCProviderMetricsPage.styles';


const PROVIDER_REPORT_NAME = "VCCProviderMetrics";

let reloadTimer = null;


/**
 * Page that displays provider report metrics
 */
function VCCProviderReportPage({ classes }) {
    const [endTime, setEndTime] = useState(Moment()),
        [startTime, setStartTime] = useState(Moment().subtract(1, 'month')),
        [alertLevel, setAlertLevel] = useState('red'),
        // [outcomeType, setOutcomeType] = useState('pro'),
        [timeInterval, setTimeInterval] = useState('24Hour'),
        [orgIds, setOrgIds] = useState(null),
        [currentJob, setCurrentJob] = useState(null),
        [mostRecentCompletedJob, setMostRecentCompletedJob] = useState(null),
        [showCode, setShowCode] = useState(false),
        [showHelp, setShowHelp] = useState(false),
        [processingJob, setProcessingJob] = useState(false);

    let [{ data: jobs, result: jobResult, loading, clearData, fetchJobs, fetchJobResult, postJob, error }] = useReportJob();


    useEffect(() => {
        if (jobs && jobs.length > 0) {
            let currentJob = jobs[0];
            setCurrentJob(currentJob);
            let recentCompletedJob = jobs.find((job) => job.status === 'done');
            setMostRecentCompletedJob(recentCompletedJob);
            if (recentCompletedJob) {
                try {
                    setStartTime(recentCompletedJob.args.startTime || recentCompletedJob.args.startDate);
                    setEndTime(recentCompletedJob.args.endTime || recentCompletedJob.args.endDate);
                    setOrgIds(recentCompletedJob.args.orgIds || recentCompletedJob.args.orgId);
                    fetchJobResult(recentCompletedJob);
                } catch (error) {
                    console.error(`error fetching job result`);
                }
            }
            if (currentJob) {
                if (currentJob?.status === 'active')
                    setProcessingJob(true);
                if (currentJob?.status === 'active' && !reloadTimer)
                    reloadTimer = setInterval(internalFetchJobs, 10000);// 10s
                if (currentJob?.status !== 'active') {
                    setProcessingJob(false);
                    clearInterval(reloadTimer);
                    reloadTimer = null;
                }
            }
        } else {
            setCurrentJob(null);
            setMostRecentCompletedJob(null);
        }
    }, [jobs]);

    useEffect(() => {
        internalFetchJobs();
        return () => {
            clearInterval(reloadTimer);
            reloadTimer = null;
            clearData();
        };
    }, []);


    function internalFetchJobs() {
        try {
            let args = [];
            args.push(`reportName=${PROVIDER_REPORT_NAME}`);
            fetchJobs(["*"], args);
        } catch (error) {
            console.error(`error fetching batch report data`);
        }
    };

    function createJob() {
        const job = {
            reportName: PROVIDER_REPORT_NAME,
            isSeries: true,
            args: { startTime, endTime, orgIds }
        };
        postJob(job);
        setProcessingJob(true);
        reloadTimer = setInterval(internalFetchJobs, 10000);// 10s
    };


    const { cards, chartData } = useMemo(() => {
        const orgs = jobResult?.orgs;
        const totals = jobResult?.totals;
        const cards = [], chartData = [];

        if (!orgs || !totals)
            return { cards, chartData };

        cards.push(
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Patients (All Time)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Completed "Funnel": {totals.patientAcceptedToS}</Typography>
                    <Typography variant='h6'>Pending: {totals.patientPendingToS}</Typography>
                    <Typography variant='h6'>Set inactive: {totals.inactivePatients}</Typography>
                    <Typography variant='h6'>No Assigned Org (Orphaned): {totals.noOrgPatients}</Typography>
                    <Typography variant='h6'>Total Patients: {totals.patients}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Providers (All Time)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Completed "Funnel": {totals.providerAcceptedToS}</Typography>
                    <Typography variant='h6'>Pending: {totals.providerPendingToS}</Typography>
                    <Typography variant='h6'>Set inactive: {totals.inactiveProviders}</Typography>
                    <Typography variant='h6'>No Assigned Org (Orphaned): {totals.noOrgProviders}</Typography>
                    <Typography variant='h6'>Total Providers: {totals.providers}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Organizations (All Time)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Registered Orgs: {totals.orgs}</Typography>
                    <Typography variant='h6'>Set Inactive: {totals.inactiveOrgs}</Typography>
                    <Typography variant='h6'>No Registrations: {totals.noRegisteredUsers}</Typography>
                    <Typography variant='h6'>Total Organizations: {totals.orgs}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Activity Pulse (by TimeFrame)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>"Active" Patients: {totals.activePatients}</Typography>
                    <Typography variant='h6'>"Active" Providers: {totals.activeProviders}</Typography>
                    <Typography variant='h6'>Total "Active" Users: {totals.active}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Reported Outcomes (Totals, by type)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>PRO (Patient): {totals.pro.total}</Typography>
                    <Typography variant='h6'>CRO (Clinician): {totals.cro.total}</Typography>
                    <Typography variant='h6'>LRO (Lab): {totals.lro.total}</Typography>
                    <Typography variant='h6'>Total: {totals.reportedOutcomes}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label={`Reported Outcomes (${alertLevel.toUpperCase()}, ${timeInterval})`} variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>PRO (Patient): {totals.pro[`${alertLevel}${timeInterval}`]}</Typography>
                    <Typography variant='h6'>CRO (Clinician): {totals.cro[`${alertLevel}${timeInterval}`]}</Typography>
                    <Typography variant='h6'>LRO (Lab): {totals.lro[`${alertLevel}${timeInterval}`]}</Typography>
                    <Typography variant='h6'>Total: {totals.reportedOutcomes}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Comms. Usage (Totals, by timeframe)" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Appointments Made: {totals.appointments}</Typography>
                    <Typography variant='h6'>(Patient) Non-English Speaker: {totals.nonEnglishSpeaker}</Typography>
                    <Typography variant='h6'>Patient Messages Sent: {totals.messagesSentByPatient}</Typography>
                    <Typography variant='h6'>Provider Messages Sent: {totals.messagesSentByProvider}</Typography>
                    <Typography variant='h6'>Total Messages Sent: {totals.messagesSent}</Typography>
                </CardContent>
            </Card>,
            <Card aria-disabled className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label="Alert Triage Round Trip" variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Red Alert Avg. RT: {totals?.triage?.redAvg}</Typography>
                    <Typography variant='h6'>Orange Alert Avg. RT: {totals?.triage?.orangeAvg}</Typography>
                    <Typography variant='h6'>Yellow Alert Avg. RT: {totals?.triage?.yellowAvg}</Typography>
                </CardContent>
            </Card>,
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader}
                    subheader={<Chip label={`Callback Activity (${timeInterval})`} variant='outlined' color='primary' />}
                />
                <Divider variant='middle' />
                <CardContent className={classes.cardContent}>
                    <Typography variant='h6'>Callbacks Requested: {totals?.callbacks?.requests}</Typography>
                    <Typography variant='h6'>Callbacks Made: {totals?.callbacks?.attempts}</Typography>
                    <Typography variant='h6'>Callbacks Unsuccessful: {totals?.callbacks?.failed}</Typography>
                    <Typography variant='h6'>Red Alerts Triaged: {0}</Typography>
                </CardContent>
            </Card>
        );

        for (const orgId in orgs) {
            const org = orgs[orgId];
            chartData.push({
                'id': org.orgName,
                'funneledUsers': org.totals.patientAcceptedToS + org.totals.providerAcceptedToS,
                'activeUsers': org.active,
                'pendingUsers': org.totals.patientPendingToS + org.totals.providerPendingToS
            });
        }

        return { cards, chartData };
    }, [jobResult, alertLevel, timeInterval]);


    return <div className={classes.root}>
        <div className={classes.box}>
            <div className={classes.row}>
                <div className={classes.left}>
                    <DateRange key='date'
                        format={'MM/dd/yyyy'}
                        startTime={startTime}
                        endTime={endTime}
                        endTimeChange={(time) => { setEndTime(Moment(time).endOf('day').toDate()); }}
                        startTimeChange={(time) => { setStartTime(Moment(time).startOf('day').toDate()); }}
                    />
                    <MultiSelectOrg selectedValue={orgIds}
                        onChange={({ orgId }) => setOrgIds(orgId)}
                    />
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={loading || processingJob}
                        onClick={createJob}
                    >
                        <T.span text="regenerate" />
                    </Button>
                    <IconButton
                        color="primary"
                        disabled={loading}
                        onClick={() => setShowCode(true)}
                    >
                        <Code />
                    </IconButton>
                    <IconButton
                        color="primary"
                        disabled={loading || processingJob}
                        onClick={internalFetchJobs}
                    >
                        {loading || processingJob ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <div className={classes.row}>
                <div className={classes.left}>
                    {(!jobResult || loading) && <Typography color="primary" variant="subtitle1">
                        {T.translate("noRecentMetrics")}
                    </Typography>}
                    {(currentJob?.status === "active" &&
                        <ProgressText text={"Updating"} />)
                        ||
                        (mostRecentCompletedJob &&
                            <Typography color="primary" variant="subtitle1">
                                {T.translate("metricsAsOf")} {Moment(mostRecentCompletedJob.endTime).format('MM/DD/YYYY')}
                            </Typography>)
                    }
                </div>
                <div className={classes.right}>
                    <Typography align='right' color='primary' variant='subtitle2'>More Info</Typography>
                    {/* <FormControl>
                        <Select value={outcomeType} onChange={(e) => setOutcomeType(e.target.value)}>
                            <MenuItem value='pro'>Patient Reported Outcome (PRO)</MenuItem>
                            <MenuItem value='cro'>Clinician Reported Outcome (CRO)</MenuItem>
                            <MenuItem value='lro'>Lab Reported Outcome (LRO)</MenuItem>
                        </Select>
                    </FormControl> */}
                    <FormControl>
                        <Select value={alertLevel} onChange={(e) => setAlertLevel(e.target.value)}>
                            <MenuItem value='red'>Red (Emergency)</MenuItem>
                            <MenuItem value='orange'>Orange (Warning)</MenuItem>
                            <MenuItem value='yellow'>Yellow (Monitor)</MenuItem>
                            <MenuItem value='green'>Green (All Clear)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Select value={timeInterval} onChange={(e) => setTimeInterval(e.target.value)}>
                            <MenuItem value='24Hour'>1 Day</MenuItem>
                            <MenuItem value='1Week'>1 Week</MenuItem>
                            <MenuItem value='1Month'>1 Month</MenuItem>
                        </Select>
                    </FormControl>
                    <Tooltip title='Click here for more information on the data displayed'
                        onClick={() => setShowHelp(true)}
                    >
                        <Help fontSize='small' color='primary' />
                    </Tooltip>
                </div>
            </div>
            <Typography color='error'>{error}</Typography>
            <Paper>
                <Grid container className={classes.grid}>
                    {cards}
                </Grid>
            </Paper>
            <Card className={classes.card}>
                <CardHeader className={classes.cardHeader} title='Org Funneled Users' />
                <CardContent className={classes.chart}>
                    <ResponsiveBar margin={{ bottom: 100, left: 50, right: 75 }}
                        labelSkipHeight={15}
                        axisBottom={{ tickRotation: -45 }}
                        data={chartData}
                        indexBy='id'
                        keys={['funneledUsers', 'activeUsers', 'pendingUsers']}
                        groupMode='grouped'
                        colors={{ scheme: 'accent' }}
                    />
                </CardContent>
            </Card>
        </div>
        <Dialog fullWidth maxWidth='md' open={showCode}>
            <DialogTitle>Provider Overview Metrics JSON</DialogTitle>
            <DialogContent>
                <Paper key='JSON-view' className={classes.jsonPaper}>
                    <ReactJson name='Provider Utilization' src={{ jobs, jobResult }} collapsed={3}
                        collapseStringsAfterLength={64} displayDataTypes={false} />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'
                    onClick={() => setShowCode(false)}>Close</Button>
            </DialogActions>
        </Dialog>
        <Dialog fullWidth maxWidth='md' open={showHelp}>
            <DialogTitle>(Overview) Provider Metrics Appendix</DialogTitle>
            <DialogContent>
                <List>
                    <ListItem>
                        <ListItemIcon><LooksOneOutlined /></ListItemIcon>
                        <ListItemText primary='Unless specified, metrics displayed within each card is from data generated no later than 30 days prior to the selected end date for the report' />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><LooksTwoOutlined /></ListItemIcon>
                        <ListItemText
                            primary='Some fields are tracked over multiple time-intervals and/or alert-levels. Use the dropdown selector beneath "Regenerate" to update corresponding card(s)'
                            secondary='Note: This dropdown is ignored during report generation, the report must already be generated before this dropdown is active [Default: 1 Day]'
                        />
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <ListItemText
                            primary='"Funnel" Users include anyone who has accepted the Terms of Service'
                            secondary='Return To Work (Protected) Plan only'
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primary='"Active" Users include anyone who has logged-in at least once during the time period specified'
                            secondary='The time period is defined by the start-date thru end-date of the report'
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'
                    onClick={() => setShowHelp(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>;
};


export default withStyles(styles)(VCCProviderReportPage);