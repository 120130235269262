const styles = ({ spacing, shadows, palette, topography, mixins }) => ({
    root: {
        backgroundColor: palette?.background?.page,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderRadius: mixins?.borderRadius,
        marginRight: '20px',
        minHeight: 'calc(90vh - 88px)',
        width: '100%',
        padding: '20px',
        "&.MuiDataGrid-root": {
            border: '0px solid',
            color: palette.text.primary
        }
    },
    box: {
        backgroundColor: palette?.background?.paper,
        border: '1px solid',
        borderColor: palette.divider,
        borderRadius: mixins?.borderRadius,
        boxShadow: shadows[2],
        display: 'inline-flex',
        flexDirection: 'column',
        gap: '1rem',
        minHeight: '80vh',
        overflowX: 'hidden',
        padding: '1rem',
        width: '100%'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    left: {
        display: 'inline-flex',
        marginLeft: '1rem'
    },
    right: {
        marginRight: '1rem'
    },
    card: {
        textAlign: 'left'
    },
    cardHeader: {
        textAlign: "center"
    },
    cardContent: {
        textAlign: "left",
        verticalAlign: 'bottom'
    },
    chart: {
        width: '80vw',
        height: '50vh'
    },
    grid: {
        backgroundColor: palette?.background?.page,
        borderRadius: mixins?.borderRadius,
        display: 'flex',
        flexDirection: 'row',
        gap: '1rem',
        justifyContent: 'start',
        alignContent: 'stretch',
        padding: '1rem'
    }
});


export default styles;