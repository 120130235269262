import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import T from 'i18n-react';
import clsx from 'clsx';

import {
    withStyles,
    Drawer,
    AppBar
} from '@material-ui/core'
import Config from '@apricityhealth/web-common-lib/Config';
import Logger from '@apricityhealth/web-common-lib/utils/Logger';
import LoginView from '@apricityhealth/web-common-lib/views/LoginView';
import MainRouter from '@apricityhealth/web-common-lib/components/MainRouter'
import NavigationMenu from '@apricityhealth/web-common-lib/components/navigationMenu'
import SiteHeader from '@apricityhealth/web-common-lib/components/siteHeader'
import { setConfig, setIdToken, setLanguage } from '@apricityhealth/web-common-lib/reduxStore/app';

import routes from './RouteList';
import styles from './App.styles'
import { GET } from '@apricityhealth/web-common-lib/utils/Connect';

const stage = Config.stage !== 'production' ? Config.stage.toUpperCase() : '';
const log = new Logger();

const GROUP = "support";
const DEFAULT_LANG = "en-us";

const version = require('../package.json').version;
log.update({ clientName: 'support-web-client', clientVersion: version });


function App({ classes }) {
    const dispatch = useDispatch();
    // const [idToken, setIdToken] = useState(null);
    const [open, setOpen] = useState(true);
    const [routeList, setRouteList] = useState([...routes]);

    // const config = useSelector(state => state.app.config);// we need to listen to config changes to reload the app if necessary
    const language = useSelector(state => state.app.language);// we need to listen to language changes to reload text
    const token = useSelector(state => state.app.idToken);

    // useEffect(() => {
    //     console.debug(`app idtoken changed `, token)
    //     setIdToken(token)
    // }, [token]);

    if (!language) {
        localStorage.setItem('language', DEFAULT_LANG);
        dispatch(setLanguage(language));
    }

    sessionStorage.setItem('group', GROUP); //the group for this app 

    useEffect(() => { //not sure we need both of these 
        console.log("Setting language:", language);
        T.setTexts(require(`./local/${language}.json`));
    }, [language]);

    useEffect(() => {
        async function getConfig() {
            try {
                const planResponse = await GET('plans');
                const plans = planResponse?.data.plans;
                const commonProtectedPlan = plans?.find(p => p.protected && p.contentId === 'common');
                const commonPlanId = commonProtectedPlan.planId;

                const configResponse = await GET('types', [commonPlanId, 'configs', 'supportConfig'])
                const configData = configResponse?.data[0];
                const route = routes.find(r => r.mainNavName === 'Platform Insight');
                if (configData) {
                    const supportConfig = JSON.parse(configData.config);
                    const userGuideUrl = supportConfig.resources.userGuide['en-us'].href;
                    route.caption = <a href={userGuideUrl} rel='noreferrer' target="_blank">{supportConfig.resources.userGuide.label}</a>;
                }

                dispatch(setConfig(configData));
                setRouteList([...routes]);
            } catch (error) {
                console.error('getConfig error:', error);
            }
        };
        if (token)
            getConfig();
        return () => {
            localStorage.removeItem('config');
        };
    }, [token]);

    console.debug(`suport app idToken `, token)
    return <>
        {token && <div className={classes.root}>
            <AppBar className={classes.appBar}>
                < SiteHeader toggleMenu={() => setOpen(!open)} />
            </AppBar>
            <Drawer
                className={classes.drawer}
                classes={{
                    paper: classes.drawerPaper,
                }}
                variant="persistent"
                open={open}
            >
                <NavigationMenu routes={routeList} className={classes.drawer} />
            </Drawer>
            <main className={clsx(classes.content, {
                [classes.contentShift]: open,
            })}>
                <div className={classes.toolbar} />
                <MainRouter routes={routeList} className={classes.content} />
            </main>
        </div>}
        {!token && <div style={{ textAlign: "center" }}>
            <LoginView title={'Support Login'} group={GROUP} stage={stage}
                appContext={
                    //this is really ugly.. but to make this work with the original login screen I have to pass appcontext with a set state
                    //to set up the store with the idtoken
                    {
                        setState: function ({ idToken }) {
                            setIdToken(idToken)
                            dispatch(setIdToken(idToken));
                        }
                    }
                }
            />
        </div>}
        {/* {!idToken && <LoginPage />} */}
    </>;
};


export default withStyles(styles)(App);