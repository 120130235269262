const styles = ({ spacing, shadows, palette, topography, mixins }) => ({
    root: {
        backgroundColor: palette?.background?.page,
        width: '100%',
        minHeight: 'calc(90vh - 88px)',
        flexGrow: 1,
        padding: '20px',
        marginRight: '20px',
        borderRadius: mixins?.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        "&.MuiDataGrid-root": {
            color: palette.text.primary,
            border: '0px solid'
        }
    },
    box: {
        display: 'flex',
        gap: '1rem',
        overflowX: 'hidden',
        boxShadow: shadows[2],
        border: '1px solid',
        borderColor: palette.divider,
        padding: '1rem',
        minHeight: '80vh',
        backgroundColor: palette?.background?.paper,
        borderRadius: mixins?.borderRadius,
        flex: 1,
        width: '100%',
        justifyContent: 'start',
        flexDirection: 'column',
    },
    left: {
        display: 'flex',
        paddingLeft: '1rem'
    },
    right: {
        display: 'flex',
        alignItems: "center",
        paddingRight: '1rem'
    },
    row: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%'
    },
    selector: {
        margin: 5
    },
    table: {
        borderRadius: mixins?.borderRadius,
        border: '10px blue',
        padding: '10px',
        backgroundColor: palette?.background?.page,
        width: '100%',
        height: "100%",
        valign: "top",
        alignItems: "top",
    }
});


export default styles;