import Config from '@apricityhealth/web-common-lib/Config';
import DateRange from '@apricityhealth/web-common-lib/components/DateRange';
import Loading from '@apricityhealth/web-common-lib/components/Loading';
import useCsvExport from '@apricityhealth/web-common-lib/hooks/useCsvExport';
import useReportJob from '@apricityhealth/web-common-lib/hooks/useReportJob';
import { isArrayValid } from '@apricityhealth/web-common-lib/utils/Services';

import T from 'i18n-react';
import Moment from 'moment-timezone';
import ReactJson from 'react-json-view';
import { useEffect, useMemo, useState } from 'react';
import { ResponsiveSankey } from '@nivo/sankey';
import { BasicTooltip } from '@nivo/tooltip';

import {
    Button,
    Card, CardActions, CardContent, CardHeader,
    Chip,
    Dialog, DialogActions, DialogContent, DialogTitle,
    Divider,
    Grid,
    IconButton,
    Paper,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import {
    Code,
    Refresh
} from '@material-ui/icons';

import DisabledIcon from '@material-ui/icons/RemoveCircle';
import ExportIcon from '@material-ui/icons/CloudDownload';
import InfoIcon from '@material-ui/icons/Info';

import styles from './PatientOnboardingMetricsPage.styles';


const ONBOARDING_REPORT_NAME = "OnboardingMetrics";
const STAGE = Config.stage === 'local' ? 'develop' : Config.stage;

let reloadTimer = null;


/**
 * Page that displays Patient Onboarding Metrics `"Patient Funnel"`
 */
function PatientOnboardingMetricsPage({ classes }) {
    const [endTime, setEndTime] = useState(Moment()),
        [startTime, setStartTime] = useState(Moment().subtract(1, 'month')),
        [showCode, setShowCode] = useState(false),
        [showCodeField, setShowCodeField] = useState(''),
        [processingJob, setProcessingJob] = useState(false);

    let [{
        data: jobList,
        result: jobResult,
        error, loading,
        fetchJobs, fetchJobResult, postJob
    }] = useReportJob();

    let [{
        appendTable,
        clearCsvWorkbook,
        exportCsv
    }] = useCsvExport();


    useEffect(() => {
        let currentJob = null, completedJob = null
        if (jobList && jobList.length > 0) {
            currentJob = jobList[0];
            completedJob = jobList.find(j => j.status === 'done');
        }

        if (completedJob) try {
            setEndTime(completedJob.args.endTime);
            setStartTime(completedJob.args.startTime);
            fetchJobResult(completedJob);
        } catch (e) {
            console.error(e);
        }

        if (currentJob) {
            if (currentJob?.status === 'active')
                setProcessingJob(true);
            if (currentJob?.status === 'active' && !reloadTimer)
                reloadTimer = setInterval(internalFetchJobs, 10000);// 10s
            if (currentJob?.status !== 'active' && reloadTimer) {
                setProcessingJob(false);
                clearInterval(reloadTimer);
                reloadTimer = null;
            }
        }
    }, [jobList]);

    useEffect(() => {
        internalFetchJobs();
        return () => {
            clearInterval(reloadTimer);
            reloadTimer = null;
        };
    }, []);


    const tableData = useMemo(() => {
        const metrics = jobResult?.metrics;
        const rows = [];

        if (!metrics)
            return;

        for (const orgId in metrics.orgs) {
            const orgMetrics = metrics.orgs[orgId], errors = orgMetrics?.errors;
            const { DuplicateTip, InvalidOrgTip } = generateErrorTooltips(errors);

            const highlight = (((orgMetrics.metrics.setPassword + orgMetrics.metrics.acceptedToS) / orgMetrics.metrics.patients) <= 0.75) ? true : false;
            rows.push(<tr key={orgMetrics.name || orgId} style={{ color: highlight ? 'red' : 'black' }}>
                <th className={classes.tableCell} scope='row' align='justify'>
                    {orgMetrics.name || "Unknown Org"} {orgMetrics.inactive && <Tooltip arrow title="Org disabled">
                        <DisabledIcon color='disabled' fontSize='small' />
                    </Tooltip>}
                </th>
                <td style={{ backgroundColor: '#E8E8E8' }} className={classes.tableCell}>{orgMetrics.metrics.patients}{InvalidOrgTip}</td>
                <td style={{ backgroundColor: '#E8E8E8' }} className={classes.tableCell}>{orgMetrics.metrics.deactivatedPatients}</td>
                <td style={{ backgroundColor: '#E8E8E8' }} className={classes.tableCell}>{orgMetrics.metrics.reactivatedPatients}</td>
                <td style={{ backgroundColor: '#E8F1F8' }} className={classes.tableCell}>{orgMetrics.metrics.referrals}{DuplicateTip}</td>
                <td style={{ backgroundColor: '#E8F1F8' }} className={classes.tableCell}>{orgMetrics.metrics.selfRegistered}</td>
                <td style={{ backgroundColor: '#E8F1F8' }} className={classes.tableCell}>{orgMetrics.metrics.providerRegistered}</td>
                <td style={{ backgroundColor: '#F9EBE1' }} className={classes.tableCell}>{orgMetrics.metrics.setPassword}</td>
                <td style={{ backgroundColor: '#FDF7E7' }} className={classes.tableCell}>{orgMetrics.metrics.acceptedToS}</td>
                <td style={{ backgroundColor: '#D2E3D3' }} className={classes.tableCell}>{orgMetrics.metrics.firstCheckInCompleted}</td>
                <td style={{ display: 'none' }}>{orgMetrics.metrics.baselineCompleted}</td>
                <td style={{ display: 'none' }}>{orgMetrics.metrics.remindersEnabled}</td>
            </tr>);
        }


        return <table key='OnboardingMetrics' className={classes.table}>
            <tbody>
                <tr>
                    <td></td>
                    <td style={{ backgroundColor: '#E8E8E8' }} className={classes.tableCell} colSpan={3} align='center'>
                        {(STAGE === 'production') ? 'Employee' : 'Patient'} Accounts
                    </td>
                    <td style={{ backgroundColor: '#E8F1F8' }} className={classes.tableCell} colSpan={3}>Step 1</td>
                    <td style={{ backgroundColor: '#F9EBE1' }} className={classes.tableCell}>Step 2</td>
                    <td style={{ backgroundColor: '#FDF7E7' }} className={classes.tableCell}>Step 3</td>
                    <td style={{ backgroundColor: '#D2E3D3' }} className={classes.tableCell}>Step 4</td>
                </tr>
                <tr>
                    <th className={classes.tableCell} scope='col' align='justify'>Org</th>
                    <td className={classes.tableCell}>{(STAGE === 'production') ? 'Employees' : 'Patients'}</td>
                    <td className={classes.tableCell}>De-activated</td>
                    <td className={classes.tableCell}>Re-activated</td>
                    <td className={classes.tableCell}>Referrals</td>
                    <td className={classes.tableCell}>Self-Registered</td>
                    <td className={classes.tableCell}>Provider-Registered</td>
                    <td className={classes.tableCell}>Set Account Password</td>
                    <td className={classes.tableCell}>Accepted ToS</td>
                    <td className={classes.tableCell}>1st Check-In Completed</td>
                    <td style={{ display: 'none' }}>Baseline Completed</td>
                    <td style={{ display: 'none' }}>Reminders Enabled</td>
                </tr>
                {rows}
            </tbody>
            <tfoot style={{ display: 'none' }}>
                <tr>
                    <td></td>
                    <td title='# of new patients across all displayed organization(s)'>
                        Total Patients: {jobResult.totalPatients}
                    </td>
                    <td title='# of new patient accounts (CreateUser) across all displayed organization(s)'>
                        New User Accounts: {metrics.newPtUsers}
                    </td>
                    <td title='# of existing patient accounts (MigrateUser) across all displayed organization(s)'>
                        Existing User Accounts: {metrics.existingPtUsers}
                    </td>
                    <td title='# of new Electronic Medical Records (Patient) across all displayed organization(s)'>
                        New EMRs: {metrics.newPtEmrs}
                    </td>
                </tr>
            </tfoot>
        </table>;
    }, [jobResult]);

    const orgData = useMemo(() => {
        const metrics = jobResult?.metrics;
        const datum = {
            deactivatedPatients: 0,
            reactivatedPatients: 0,
            providerRegistered: 0,
            referrals: 0,
            selfRegistered: 0,
            totalPatients: 0,
            acceptedToS: 0,
            baselineCompleted: 0,
            firstCheckInCompleted: 0,
            setPassword: 0,
            remindersEnabled: 0,
            dupReferrals: 0,
            invalidOrgPts: 0
        };

        if (!metrics)
            return;

        for (const orgId in metrics.orgs) {
            const orgData = metrics.orgs[orgId];
            for (const patientId in orgData.errors) {
                const ptErrors = orgData.errors[patientId];
                for (const error of ptErrors) {
                    switch (error.message) {
                        case 'DUPLICATE_REFERRAL':
                            datum.dupReferrals += error.count;
                            break;
                        case 'INVALID_PRIMARY_ORG_ID':
                            datum.invalidOrgPts++;
                            break;
                        default:
                            console.warn(`Tooltip for error '${error.message}' not generated`);
                            break;
                    };
                }
            }

            datum.deactivated += orgData.metrics.deactivatedPatients;
            datum.reactivated += orgData.metrics.reactivatedPatients;
            datum.referrals += orgData.metrics.referrals - datum.dupReferrals;
            datum.providerRegistered += orgData.metrics.providerRegistered;
            datum.selfRegistered += orgData.metrics.selfRegistered;
            datum.totalPatients += orgData.metrics.patients;
            datum.setPassword += orgData.metrics.setPassword + orgData.metrics.selfRegistered;
            datum.acceptedToS += orgData.metrics.acceptedToS;
            datum.baselineCompleted += orgData.metrics.baselineCompleted;
            datum.firstCheckInCompleted += orgData.metrics.firstCheckInCompleted;
            datum.remindersEnabled += orgData.metrics.remindersEnabled;
        }


        /*Sankey Chart*/
        return <Card className={classes.card}>
            <CardHeader title="Quick View: All Organizations" subheader={
                <Chip variant='outlined' size='small' className={classes.chip} label={
                    `${Moment(startTime).format('MMMM DD, YYYY')} - ${Moment(endTime).format('MMMM DD, YYYY')}`
                } />
            } />
            <Divider variant='middle' />
            <CardContent className={classes.cardContent}>
                <ResponsiveSankey enableLinkGradient
                    layout='horizontal' align='justify' sort='input'
                    linkContract={5} nodeBorderRadius={3} nodeThickness={16}
                    margin={{ right: 25, left: 25, top: 50, bottom: 25 }}
                    colors={(node) => node.nodeColor}
                    layers={['links', 'nodes', Label.bind(datum), 'legends', Title]}
                    linkTooltip={({ link }) => {
                        let value = '';
                        if (link.source.id === 'referrals') {// Dislay # of duplicate referrals
                            value = `${link.source.nodeLabel} > ${link.target.nodeLabel}: ${datum.referrals - datum.dupReferrals}`;
                            if (datum.dupReferrals)
                                value += ` [-${datum.dupReferrals} duplicates]`;
                        }
                        else if (link.source.layer === 0)// Display onboarding method counts
                            value = `${link.source.nodeLabel} > ${link.target.nodeLabel}: ${datum[link.source.id]}`;
                        else// Display onboarding step counts
                            value = `${link.source.nodeLabel} > ${link.target.nodeLabel}: ${datum[link.target.id]}`;

                        return <BasicTooltip enableChip color={link.color} value={value} />;
                    }}
                    nodeTooltip={({ node }) => {
                        let value = `${node.nodeLabel}: ${datum[node.id]}`;
                        if (node.id === 'referrals' && datum.dupReferrals)// Display # of duplicate referrals
                            value += ` [${datum.dupReferrals} duplicates]`;
                        if (node.id === 'patients' && datum.invalidOrgPts)// Display # of pts. w/ invalid orgId
                            value += ` [${datum.invalidOrgPts} w/ invalid primaryOrgId(s)]`;
                        return <BasicTooltip enableChip color={node.nodeColor} value={value} />;
                    }}
                    data={{
                        nodes: [
                            { id: "totalPatients", nodeColor: '#DBE7F1', nodeLabel: "Patients" },
                            { id: "referrals", nodeColor: '#E8F1F8', nodeLabel: "Referrals" },
                            { id: "providerRegistered", nodeColor: '#E8F1F8', nodeLabel: "Provider-Registered" },
                            { id: "selfRegistered", nodeColor: '#E8F1F8', nodeLabel: "Self-Registered" },
                            { id: "reactivatedPatients", nodeColor: '#E8E8E8', nodeLabel: "Reactivated Patients" },
                            { id: "setPassword", nodeColor: '#EEBDA7', nodeLabel: "Set Password" },
                            { id: "acceptedToS", nodeColor: '#FBE9B5', nodeLabel: "Accepted ToS" },
                            { id: "firstCheckInCompleted", nodeColor: '#75A579', nodeLabel: "1st Check-In Completed" }
                        ],
                        links: [
                            {
                                source: "referrals",
                                target: "totalPatients",
                                value: datum.referrals
                            },
                            {
                                source: "providerRegistered",
                                target: "totalPatients",
                                value: datum.providerRegistered
                            },
                            {
                                source: "selfRegistered",
                                target: "totalPatients",
                                value: datum.selfRegistered
                            },
                            {
                                source: "reactivatedPatients",
                                target: "totalPatients",
                                value: datum.reactivatedPatients
                            },
                            {
                                source: "totalPatients",
                                target: "setPassword",
                                value: datum.setPassword
                            },
                            {
                                source: "setPassword",
                                target: "acceptedToS",
                                value: datum.acceptedToS
                            },
                            {
                                source: "acceptedToS",
                                target: "firstCheckInCompleted",
                                value: datum.firstCheckInCompleted
                            }
                            // +1 each value prevents empty node/link (e.g. floating label)
                        ].map((link) => ({ ...link, value: link.value + 1 }))
                    }}
                />
            </CardContent>
            <CardActions disableSpacing>
                <IconButton
                    color='primary'
                    disabled={processingJob}
                    onClick={() => {setShowCode(true); }}
                >
                    <Code />
                </IconButton>
            </CardActions>
        </Card>;
    }, [jobResult, processingJob]);


    function createJob() {
        const job = {
            reportName: ONBOARDING_REPORT_NAME,
            isSeries: true,
            args: { startTime, endTime }
        };
        postJob(job);
        setProcessingJob(true);
        reloadTimer = setInterval(internalFetchJobs, 10000);// 10s
    };

    function downloadCsv() {
        const FORMAT = "YYYY-MM-DD",
            START = Moment(startTime).format(FORMAT),
            END = Moment(endTime).format(FORMAT);

        clearCsvWorkbook();
        appendTable({ title: "Onboarding (by org)", htmlTable: tableData });
        exportCsv({ fileName: `${ONBOARDING_REPORT_NAME}_${START}_Thru_${END}` });
    };

    function internalFetchJobs() {
        try {
            const args = [`reportName=${ONBOARDING_REPORT_NAME}`],
                params = ['*'];
            fetchJobs(params, args);
        } catch (e) {
            console.error('error fetching job(s)', e);
        }
    };


    return <div className={classes.root}>
        <div className={classes.box}>
            <Typography variant='h4'>{(STAGE === 'production')
                ? "Onboarding Employee Funnel"
                : "Onboarding Patient Funnel"
            }</Typography>
            {(error) ? <Typography color='error'>{error}</Typography> : null}
            <div className={classes.row}>
                <div className={classes.left}>
                    <DateRange key='date'
                        format={'MM/dd/yyyy'}
                        startTime={startTime}
                        endTime={endTime}
                        endTimeChange={(time) => { setEndTime(Moment(time).endOf('day').toDate()) }}
                        startTimeChange={(time) => { setStartTime(Moment(time).startOf('day').toDate()) }}
                    />
                </div>
                <div className={classes.right}>
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={(loading || processingJob)}
                        onClick={createJob}
                    >
                        {T.translate("regenerate")}
                    </Button>
                    <IconButton
                        color='primary'
                        disabled={(loading || processingJob)}
                        onClick={downloadCsv}
                    >
                        <ExportIcon />
                    </IconButton>
                    <IconButton
                        color='primary'
                        disabled={loading}
                        onClick={() => setShowCode(true)}
                    >
                        <Code />
                    </IconButton>
                    <IconButton
                        color="primary"
                        onClick={internalFetchJobs}
                    >
                        {(loading || processingJob) ? <Loading /> : <Refresh />}
                    </IconButton>
                </div>
            </div>
            <br />
            {((loading || processingJob) && <div className={classes.left}>
                <Typography>Loading Metrics...</Typography>
            </div>) || ((!processingJob && !jobResult) && <div className={classes.left}>
                <Typography>Generate a new report</Typography>
            </div>)}
            <Grid container className={classes.grid}>{(orgData && tableData)
                ? [
                    <Grid item key='overview-chart'>{orgData}</Grid>,
                    <Grid item key='org-table'>{
                        <Card className={classes.card}>
                            <CardHeader title="Breakdown by Organization"/>
                            <Divider variant='middle' />
                            <CardContent className={classes.cardContent}>
                                {tableData}
                            </CardContent>
                        </Card>
                    }</Grid>
                ]
                : null
            }</Grid>
        </div>
        <Dialog fullWidth maxWidth='md' open={showCode} onClose={(e) => setShowCode(false)}>
            <DialogTitle>Onboarding Metrics JSON</DialogTitle>
            <DialogContent>
                <Paper key='JSON-view' className={classes.jsonPaper}>
                    <ReactJson name='Onboarding Metrics' src={{ job: jobList, jobResult }}
                        collapseStringsAfterLength={64} displayDataTypes={false} coll
                        shouldCollapse={(field) => {
                            if (!showCodeField && field.namespace.length <= 3)// default expand ()
                                return false;
                            if ([// code-fields to expand for specific org
                                'Onboarding Metrics',
                                'jobResult',
                                'metrics',
                                'orgs',
                                showCodeField,
                                'errors'
                            ].includes(field.name))
                                return false;
                            return true;
                        }}
                    />
                </Paper>
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' color='primary'
                    onClick={() => { setShowCodeField(''); setShowCode(false); }}>Close</Button>
            </DialogActions>
        </Dialog>
    </div>
};


/**
 * Add title to SVG Chart
 * @param {SankeyDataProps} props Chart layer data
 * @returns {SVGTextElement}
 */
function Title(props) {
    return <text x={props.width / 2} y={-25} dominantBaseline='central' textAnchor='middle'
        fontSize={20} fontStyle='oblique' fontVariant='titling-caps' fontWeight={50}
    >
        Onboarding Metrics
    </text>;
};
/**
 * Format individual data-node labels on the data chart
 * 
 * @this {{ metrics: {[key: string]: number} }}
 * @param {SankeyLinkDatum} props Chart link data
 * @returns {SVGTextElement}
 */
function Label(props) {
    if (props.nodes) {// sankey chart)
        return props.nodes.map((node) => {
            if (node.height > 125)// check if label fits inside node (vertical-bar)
                return <text key={node.id} dominantBaseline='central' textAnchor='middle' transform={
                    `translate(${node.x + (node.width / 2)}, ${node.y + (node.height / 2)}) rotate(-90)`
                }>
                    {node.nodeLabel}: {this[node.id]}
                </text>;
            else if (node.depth === 0)// check if label is in the 1st column (left-align link)
                return <text key={node.id} dominantBaseline='central' textAnchor='start'
                    transform={`translate(${node.x1}, ${node.y + (node.height / 2)})`}
                >
                    {node.nodeLabel}: {this[node.id]}
                </text>;
            else// otherwise label is in other columns (right-align link)
                return <text key={node.id} dominantBaseline='central' textAnchor='end'
                    transform={`translate(${node.x}, ${node.y + (node.height / 2)})`}
                >
                    {node.nodeLabel}: {this[node.id]}
                </text>;
        });
    }

    console.warn('SVG-Label layer not defined:', props);
    return null;
};


/**
 * @param {Object} errors Errors object `[keyed by patientId]`
 * @returns {{
 *   DuplicateTip: ?JSX.Element,
 *   InvalidOrgTip: ?JSX.Element
 * }} Generated Patient Error Tooltips
 */
function generateErrorTooltips(errors) {
    const tooltips = {
        DuplicateTip: null,
        InvalidOrgTip: null
    };

    if (!errors || !isArrayValid(Object.keys(errors)))
        return tooltips;

    const duplicates = [], invalidOrgs = [], duplicatePts = [], invalidOrgPts = [];
    for (const patientId in errors) {
        const ptErrors = errors[patientId];
        let numDuplicates = 0, emrId = '';

        for (const error of ptErrors) {
            switch (error.message) {
                case "INVALID_PRIMARY_ORG_ID":
                    emrId = error.emrId;
                    invalidOrgPts.push(patientId);
                    break;
                case "DUPLICATE_REFERRAL":
                    numDuplicates += error.count;
                    duplicatePts.push(patientId);
                    break;
                default:
                    console.warn(`Tooltip for error '${error.message}' not generated`);
                    break;
            };
        }

        if (numDuplicates > 0) duplicates.push(
            <tr key={`Duplicate-Referral-${patientId}`}>
                <th align='justify'>{patientId}</th>
                <td align='center'>{numDuplicates}</td>
            </tr>
        );
        if (emrId) invalidOrgs.push(
            <tr key={`Invalid-Org-${patientId}`}>
                <th align='justify'>{patientId}</th>
                <td align='center'>{emrId}</td>
            </tr>
        );
    }

    if (isArrayValid(duplicates)) tooltips.DuplicateTip = <Tooltip arrow
        onClick={() => navigator.clipboard.writeText(duplicatePts.join('\n'))}
        title={
            <table key='Onboarding-Duplicate-Tooltip'>
                <thead>
                    <tr>
                        <th>PatientId</th>
                        <th>Duplicates</th>
                    </tr>
                </thead>
                <tbody>{duplicates}</tbody>
                <tfoot>
                    <tr>
                        <td align='center' colSpan={2}>
                            <i>(Click me to copy PatientIds)</i>
                        </td>
                    </tr>
                </tfoot>
            </table>
        }>
        <InfoIcon color='disabled' fontSize='small' />
    </Tooltip>;
    if (isArrayValid(invalidOrgs)) tooltips.InvalidOrgTip = <Tooltip arrow
        onClick={() => navigator.clipboard.writeText(invalidOrgPts.join('\n'))}
        title={
            <table key='Onboarding-Invalid-Org-Tooltip'>
                <thead>
                    <tr>
                        <th>PatientId</th>
                        <th>EmrId</th>
                    </tr>
                </thead>
                <tbody>{invalidOrgPts}</tbody>
                <tfoot>
                    <tr>
                        <td align='center' colSpan={2}>
                            <i>(Click me to copy PatientIds)</i>
                        </td>
                    </tr>
                </tfoot>
            </table>
        }>
        <InfoIcon color='disabled' fontSize='small' />
    </Tooltip>;


    return tooltips;
};


export default withStyles(styles)(PatientOnboardingMetricsPage);